<template>
  <div class="flex">
    <NuxtLink
      v-for="(social, index) in props.socials"
      :key="index"
      :to="social.link"
      :target="social.target"
      :title="social.title"
      class="group mr-5 flex size-10 items-center justify-center bg-white last:mr-0"
    >
      <UiIcon
        :width="20"
        :height="20"
        :name="social.icon"
        class="text-primary transition-transform group-hover:scale-125"
      />
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import UiIcon from '../UiIcon/UiIcon.vue'

export interface UiFooterSocialInterface {
  link: string
  icon: string
  title?: string
  target?: string
}

const props = defineProps<{
  socials: Array<UiFooterSocialInterface>
}>()
</script>
